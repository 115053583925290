<template>
  <div class="web-cg">
    <div class="cg-top">
      <div class="ct-left">
        <div class="imt-cats">
        </div>
        <div class="cg-cats">
         <span class="cg-title" v-for="(item,index) in cgTypeList"
               :class="{activeCat:catIndex==index}" @click="choiceCat(index,item.value)">{{item.name}}</span>
        </div>
        <div class="gd">
          <div class="cg-items" v-if="cgs.length>0">
            <div class="cg-item" v-for="item in cgs" @click="addItem(item)">
              <span class="cti-name">{{item.cgName}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ct-right">
        <div class="cjmb">
          <span style="font-size: 13px;display: block;text-align: center;font-weight: bold">结算列表</span>
        </div>
        <div class="buy-cgs">
          <el-table
            ref="singleTableRef"
            height="100%"
            :data="datas"
            @row-click="setRow"
            highlight-current-row
            style="width: 100%">
            <el-table-column width="50" align="center">
              <template slot-scope="scope">
                <li class="el-icon-delete-solid" style="color:red;cursor: pointer;" @click="delItem"></li>
              </template>
            </el-table-column>
            <el-table-column property="cgName" label="名称" width="100" align="center">
            </el-table-column>
            <el-table-column property="openAmount" label="价格" width="100" align="center">
              <template slot-scope="scope">
                <el-input-number
                  style="width: 90px"
                  size="mini"
                  v-model="scope.row.price"
                  auto-complete="off"
                  :precision="1"
                  :controls="false"
                  maxlength="6"
                  v-on:input="calculation()"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column property="empName" label="员工" align="center">
              <template slot-scope="scope">
                <div class="buy-xiaoshou">
                  <span style="width: 60px;line-height: 28px;font-size: 13px">{{scope.row.emp.empName}}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="cg-emp">
          <div class="cg-gw">
            <span class="gw-title">全部员工</span>
            <div class="emp-h">
              <div class="gw-emp" v-for="item in emps" @click="setEmp(item,1)">
                <span class="name" :class="{hui: item.check}">{{item.nickName}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cg-bottom">
      <div class="cjs-btn">
        <span>总计：</span>
        <span class="total">￥{{ total }}</span>
        <el-dropdown trigger="click" class="payment" @command="handleCommand">
          <el-button type="primary" class="payment">
            结算<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="2">现金</el-dropdown-item>
            <el-dropdown-item command="3">微信</el-dropdown-item>
            <el-dropdown-item command="4">支付宝</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <!-- 删除单子 -->
    <el-dialog title="结算提示框" :close-on-click-modal="false" :visible.sync="saveVisible" width="200px">
      <p style="margin-bottom: 18px">提示:补单请选择时间,不是补单请忽略!</p>
      <div style="text-align: center;">
        <el-date-picker
          size="small"
          v-model="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="saveVisible=false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveCgOrder">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";

  export default {
    name: "expenses",
    components: {},
    data() {
      return {
        cgs: [],
        dataIndex: '',
        datas: [],
        payType:'',
        emps: [],

        catIndex: 0,
        cgTypeList: [
          {'name': '开支', 'value': '0'},
          {'name': '管账', 'value': '1'},
          {'name': '借支', 'value': '2'},
          {'name': '收入', 'value': '3'},
        ],

        total: 0,
        date:'',
        donationAmount: 0,
        saveVisible: false,
      };
    },

    async created() {
      this.getSwConsumeGoodsList();
      this.getEmpList();
      // this.initWebSocket()
    },

    methods: {

      choiceCat(index) {
        this.catIndex = index
        this.getSwConsumeGoodsList()
      },

      //获取会员卡列表
      async getSwConsumeGoodsList() {
        let res = await this.$get("/sw/getSwConsumeGoodsList", {token: getToken(), cgType: this.catIndex});
        this.cgs = res.cgs;
      },

      //添加开支项目
      addItem(item) {
        this.datas.push({
          cgId: item.id,
          cgName: item.cgName,
          cgType: item.cgType,
          price: 0,
          empId: '',
          emp: {},
        })
        this.dataIndex = this.datas.length - 1
        this.$refs.singleTableRef.setCurrentRow(this.datas[this.dataIndex], true);
        this.getEmpList()
      },


      //移除已选项目
      delItem(index) {
        let item = this.datas[index];
        this.datas.splice(index, 1);
        this.calculation()
        this.getEmpList();
        this.$refs.singleTableRef.setCurrentRow(-1)
      },

      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmps", {token: getToken()});
        this.emps = res.emps;
      },

      setRow(item) {
        this.dataIndex = this.datas.indexOf(item);
        this.changeEmpFalse();
        this.changeEmpTrue(item.emp)
      },

      //计算总金额
      calculation() {
        let total = 0
        this.datas.forEach(item => {
          var to = parseFloat(total) + parseFloat(item.price)
          total = to.toFixed(1)
        })
        this.total = total
      },

      //设置员工
      async setEmp(item) {
        if (this.datas.length > 0) {
          this.changeEmpFalse();
          if (!item.check) {
            this.datas[this.dataIndex].emp = {
              empId: item.id,
              empName: item.nickName,
            }
            this.datas[this.dataIndex].empId = item.id
          } else {
            this.datas[0].emp = {}
            this.datas[this.dataIndex].empId = ''
          }
          item.check = !item.check
        }
      },
      handleCommand(command) {
        if (this.datas.length <= 0) {
          this.$message.error("请选择要结算的商品!");
          return;
        }
        this.payType = command
        this.saveVisible = true
      },

      async saveCgOrder() {
        let data = {
          cgs: JSON.stringify(this.datas),
          total: this.total,
          payType: this.payType,
          token: getToken(),
          date:this.date
        }
        let res = await this.$post("/sw/saveCgOrder", data);
        if (res.code == 200) {
          this.datas=[]
          this.total=0
          this.payType=''
          this.$message.success(res.message);
          this.saveVisible = false
        } else {
          this.$message.error(res.message);
        }
      },

      //更改状态
      changeEmpFalse() {
        this.emps.forEach(item => {
          item.check = false
        })
      },

      //更改状态
      changeEmpTrue(emp) {
        this.emps.forEach(item => {
          if (emp.empId == item.id) {
            item.check = true
          } else {
            item.check = false
          }
        })
      },
    },
  };
</script>


<style lang="scss" scoped>
  .web-cg {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    margin-right: 5px;
  }

  .cg-top {
    width: 100%;
    height: 93%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
  }

  .ct-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
  }

  .ct-title {
    display: block;
    height: 50px;
    line-height: 50px;
    border-bottom: #E6E6E6 solid 1px;
    text-align: center;
    font-weight: bold;
  }

  .gd {
    height: 90%;
    overflow: hidden; // 超出部分隐藏
    overflow-y: scroll; // 设置y轴方向的滚动条
  }

  .cg-items {
    width: 95%;
    padding: 0px 2%;
    display: flex;
    flex-flow: row wrap;
  }

  .cg-item {
    flex: 1;
    box-sizing: border-box;
    padding: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: calc((100% - 60px) / 6); // 我这里一行显示4个 所以是/4  一行显示几个就除以几
    min-width: calc((100% - 60px) / 6);
    max-width: calc((100% - 60px) / 6);
    text-align: center;

    &:nth-child(4n + 4) {
      margin-right: 0;
    }
  }

  .cti-name {
    margin-top: 3px;
    line-height: 30px;
    margin-left: 5px;
    font-size: 13px;
    border: silver solid 1px;
  }

  .ct-right {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .cjmb {
    display: block;
    height: 50px;
    line-height: 50px;
    border-bottom: #E6E6E6 solid 1px;
    flex-direction: row;
    padding-left: 26px;
    border-right: #F2F2F2 solid 5px;
    font-size: 13px;
  }

  .buy-cgs {
    height: 65%;
    border-right: #F2F2F2 solid 5px;
    font-size: 11px;
  }

  .buy-xiaoshou {
    display: flex;
    flex-direction: row;
  }

  .cg-emp {
    height: 30%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .cg-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 100%;
    cursor: pointer;
    padding-left: 8px;
  }

  .gw-emp .hui {
    width: 100%;
    cursor: pointer;
    background-color: #DDDDDD;
  }

  .cg-bottom {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 6%;
    background-color: #FFFFFF;
  }

  .cjs-btn {
    width: 300px;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
  }

  .cjs-btn span {
    line-height: 51px;
    width: 80px;
  }

  .cjs-btn .total {
    width: 50%;
    color: red;
  }

  .payment {
    float: right;
    width: 100px;
    height: 51px;
    border: 0;
    background-color: #e82742;
    color: #fff;
    cursor: pointer;
    margin-left: 8px;
  }

  .cg-cats {
    width: 100%;
    border-bottom: #E6E6E6 solid 1px;
    height: 47px;
    line-height: 47px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .cg-title {
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin: 0 5px;
  }

  .activeCat {
    color: red;
    border-bottom: red solid 2px;
  }

</style>
